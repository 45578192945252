import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Howl } from "howler";

const backgrounds = [
  { emotion: "Vision", url: "/images/Vision.png" },
  { emotion: "Angry", url: "/images/angry.png" },
  { emotion: "Confronting", url: "/images/Confronting.png" },
  { emotion: "Evil", url: "/images/Evil.png" },
  { emotion: "Intense", url: "/images/Intense.png" },
  { emotion: "Really", url: "/images/Really.jpg" },
  { emotion: "Sad", url: "/images/sad.png" },
  { emotion: "Shy", url: "/images/Shy.png" },
  { emotion: "Thinking", url: "/images/Thinking.png" },
];

const transitionVideoUrl = "/videos/Animation Banner ai assistant 2 Black Effect.mp4";

const App = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [messages, setMessages] = useState([]);
  const [msgInput, setMsgInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeBackground, setActiveBackground] = useState(backgrounds[0]);
  const [showTransition, setShowTransition] = useState(false);
  const [showDefaultQuestions, setShowDefaultQuestions] = useState(true);


  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState("");

  const handlePasswordSubmit = (event) => {
    event.preventDefault();
    // Replace this logic with your password verification
    if (password === "your_password") {
      setIsAuthenticated(true);
    } else {
      alert("Incorrect password");
    }
  };

  const chatBodyRef = useRef(null);

  const defaultQuestions = [
    "Who are you?",
    "What is the Bloom DAO?",
    "How can I participate?",
  ];

  const loadSound = new Howl({
    src: ["/sounds/background.mp3"],
    volume: 0.1,
    loop: true,
  });

  const sendSound = new Howl({
    src: ["/sounds/presenter.wav"],
    volume: 1.0,
  });

  const receiveSound = new Howl({
    src: ["/sounds/thinking1.wav"],
    volume: 1.0,
    loop: true,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages]);

  const switchBackground = () => {
    const randomBackground = backgrounds[Math.floor(Math.random() * backgrounds.length)];
    setActiveBackground(randomBackground);
  };

  const handleQuestionClick = (question) => {
    setShowDefaultQuestions(false);
    setMsgInput(question);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (!msgInput.trim()) return;

    sendSound.play();
    loadSound.fade(0.2, 0.0, 1000);
    receiveSound.play();
    receiveSound.fade(0.0, 1, 1000);

    setMessages((prev) => [...prev, { text: msgInput, sender: "user" }]);
    setMsgInput("");
    setLoading(true);

    setShowTransition(true);

    try {
      const response = await fetch(
        "https://aicontrol.bloomverse.io/2ecfad32-53c5-0bf9-85e0-3afee0f5fdd0/message",
        {
          method: "POST",
          body: JSON.stringify({
            userId: "user123",
            roomId: "room123",
            userName: "user123",
            name: "user123",
            text: msgInput,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      if (response.status !== 200) {
        throw data.error || new Error(`Request failed with status ${response.status}`);
      }

      setTimeout(() => {
        setShowTransition(false);
        switchBackground();
      }, 1000);

      const elevenLabsResponse = await fetch(
        "https://api.elevenlabs.io/v1/text-to-speech/5wi8UhzhkrXZroPw6p4a",
        {
          method: "POST",
          headers: {
            Accept: "audio/mpeg",
            "Content-Type": "application/json",
            "xi-api-key":
              "sk_305e7192545679f6d5123b79848f961d2412c79f2e7a45f8",
          },
          body: JSON.stringify({
            text: data[0].text,
            stability: 0.4,
            clarity: 0.85,
            model_id: "eleven_monolingual_v1",
          }),
        }
      );

      if (!elevenLabsResponse.ok) {
        throw new Error("Failed to fetch audio from Eleven Labs");
      }

      const audioBlob = await elevenLabsResponse.blob();
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);

      audio.play();

      setMessages((prev) => [...prev, { text: data[0].text, sender: "ai" }]);
    } catch (error) {
      console.error(error);
      alert("Error: Unable to get a response from the AI.");
      setShowTransition(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
  <Overlay>
          <FormContainer>
            <Title>Enter Password to Continue</Title>
            <PasswordForm onSubmit={handlePasswordSubmit}>
              <PasswordInput
                type="password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <SubmitButton type="submit">Continue</SubmitButton>
            </PasswordForm>
          </FormContainer>
        </Overlay>
     
      <VideoContainer>
        <TransitionVideo
          src={transitionVideoUrl}
          style={{ opacity: showTransition ? 1 : 0 }}
          loop
          muted
          autoPlay
        />
        <ImageBackground
          src={activeBackground.url}
          style={{ opacity: showTransition ? 0 : 1 }}
          alt={activeBackground.emotion}
        />
      </VideoContainer>
    {/*  <ChatWindow
        style={{
          width: windowWidth > 768 ? "600px" : "90%",
          height: windowHeight > 600 ? "500px" : `calc(${windowHeight - 20}px)`,
        }}
      >
        {showDefaultQuestions && (
          <DefaultQuestionsContainer>
            {defaultQuestions.map((question, index) => (
              <QuestionButton
                key={index}
                onClick={() => handleQuestionClick(question)}
              >
                {question}
              </QuestionButton>
            ))}
          </DefaultQuestionsContainer>
        )}
        <ChatBody ref={chatBodyRef}>
          {messages.map((msg, index) => (
            <Message key={index} sender={msg.sender}>
              {msg.text}
            </Message>
          ))}
          {loading && (
            <LoadingAnimation>
              <Dot />
              <Dot />
              <Dot />
            </LoadingAnimation>
          )}
        </ChatBody>
        <ChatInput onSubmit={onSubmit}>
          <Input
            type="text"
            placeholder="Type a message..."
            value={msgInput}
            onChange={(e) => setMsgInput(e.target.value)}
          />
          <SendButton type="submit">Send</SendButton>
        </ChatInput>
      </ChatWindow> */}


    </Container>

    
  );
};

// Styled Components
const DefaultQuestionsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  background-color: #f0f0ff;
  border-radius: 10px;
`;

const QuestionButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #c576f6;
  color: white;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #9c4bcc;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const ParticlesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  pointer-events: none;
`;

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const TransitionVideo = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 1s ease-in-out;
  z-index: 2;
`;

const ImageBackground = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 1s ease-in-out;
  z-index: 1;
`;

const ChatWindow = styled.div`
  position: fixed;
  bottom: 10px;
  left: 10px;
  max-width: 90%;
  max-height: 500px;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 15;
`;

const ChatBody = styled.div`
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Message = styled.div`
  max-width: 70%;
  padding: 10px;
  border-radius: 10px;
  align-self: ${(props) =>
    props.sender === "user" ? "flex-end" : "flex-start"};
  background-color: ${(props) =>
    props.sender === "user" ? "#007bff90" : "#C576F690"};
  color: white;
`;

const LoadingAnimation = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 5px;
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  background-color: #007bff;
  border-radius: 50%;
`;

const ChatInput = styled.form`
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
`;

const Input = styled.input`
  flex: 1;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  margin-right: 10px;
`;

const SendButton = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const FormContainer = styled.div`
  background: transparent;
  padding: 20px;
  border-radius: 10px;
  width:300px;
  text-align: center;
`;

const Title = styled.h2`
  font-size :14px;
  margin-bottom: 20px;
  color: #fff;
`;

const PasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PasswordInput = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 16px;
  background: #ffffff20;
  color:#ffffff;
  border:1px solid purple;
  borderRadius: 14px;
  lineHeight: 20px;
  marginBottom:20px;
  boxShadow:0px 0px 10px #ffffff50;
  gap: 10px;
`;

const SubmitButton = styled.button`
  padding: 10px;
  background-color: #EE0FE5;
  color: white;
  border: none;
  border-radius: 16px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

export default App;
